import { ITheme } from './interfaces/ITheme'
import IColors from './interfaces/IColors'

const BezvasportColors: IColors = {
  listItemSeparator: '#f2f2f2',
  menuBackground: '#ffffff',
  menuItemBackgroundSecondary: '#eeeeee',
  onBackgroundSecondary: '#151414',
  sectionSeparator: '#dedede',
  accent: '#E90114',
  neutralBorder: '#c1c1c1',
  background: '#ffffff',
  onBackground: '#000000',
  inputPlaceholder: '#8d8d8d',
  tableBackground: '#dcdcdc',
  highlight: '#00afff',
  placeholder: '#8d8d8d',
  accentSecondary: '#18A72B',
  accentTertiary: '#2ACDA0',
  error: '#e74c3c',
  errorSecondary: '#f01e4b',
  success: '#07bc0c',
  info: '#3498db',
  infoSecondary: '#666666',
  successTertiary: '#238C55',
  facebook: '#4267b2',
  twitter: '#6abaeb',
  warning: '#f1c40f',
  successSecondary: '#04AF8A',
  giftCardBackground: '#ffc800',
  specialLandingPageBackground: '#feb202',
  discountLabelBackgroundColor: '#f04e23',
  discountLabelColor: '#ffffff',
  freeShippingLabelBackgroundColor: '#05ae8a',
  freeShippingLabelColor: '#ffffff',
  toolbar: 'transparent',
  toolbarSeparator: '#dedede',
  footerBackground: '#000000',
  mobileBackground: '#f2f2f2',
  bannerBackground: '#eae4fb',
  headerButton: '#000000',
  blackFridayBackgroundButton: '#ffc700',
  blackFridayMainBackground: '#121212',
  blackFridayHeaderBackground: '#e90114',
  luigiMainColor: '#120872',
  luigiHoverColor: '#fffccd',
  christmasBackgroundButton: '#437144',
  christmasMainBackground: '#f3f1ec',
  valentineBackgroundButton: '#d64d70',
  valentineMainBackground: '#f3eced',
  valentineArrow: '#ffc800',
  summerBackgroundButton: '#ffffff',
  summerMainBackground: '#eccda7',
  checkoutMainColor: '#808080',
  blogNeutralBackground: '#f4f4f4',
  sizeTableDescription: '#757575',
  sizeTableNeutral1: '#f0f0f0',
  sizeTableNeutral2: '#f5f5f5',
  sizeTableNeutral3: '#e3e3e3',
  ratingProgressBarNeutral: '#d9d9d9',
  ratingStar: '#FFC800',
  ratingReviewDate: '#989898',
  ratingReviewInfoNeutral: '#707070',
  ratingReviewVerified: '#35AB6D',
  facebookHover: '#045FD4',
  googleButtonBorder: '#747775',
  loginInactiveBackground: '#5A5A5A',
  buttonPopup: '#FFC800',
  cartCoupon: '#47895E',
  cartCouponBackground: '#e0f2e9',
  greyBackground: '#f8f8f8'
}

export const BezvasportTheme: ITheme = {
  colors: BezvasportColors,
  hasToolbarBackground: false,
  borderRadius: 0,
  buttonBorderRadius: 2,
  headerCart: 'border: 1px solid #000000;',
  headerSearchInput: `
    border: 1px solid #000000;
    &:focus {
      border: 1px solid #000000;
    }
  `,
  headerSearchButton: `
    &:hover {
      border-left: 1px solid #f04e23;
      border-top: 1px solid #000000;
      border-right: 1px solid #000000;
      border-bottom: 1px solid #000000;
    }
  `,
  footerTextTransparency: 0.4,
  separatorWidth: '',
  logo: {
    size: {
      desktop: {
        width: 145,
        height: 32
      },
      tablet: {
        width: 145,
        height: 32
      },
      mobile: {
        width: 80,
        height: 18
      }
    }
  },
  logoWidth: 80,
  logoHeigth: 18
}
