import {
  Blog,
  Campaign,
  Category,
  GiftBox,
  Manufacturer,
  Order,
  OrderData,
  Product,
  ProductSize,
  SeoTags
} from '@/network/graphql.g'
import { GTMProvider, useGTMDispatch } from '@elgorditosalsero/react-gtm-hook'
import { FC, createContext, useContext, useEffect } from 'react'
import { useUser } from './userProvider'
import isEqual from 'lodash.isequal'
import { useExperiment } from './experimentProvider'
import {
  getCustomerFromMailing,
  getLoginEvent,
  getMhubUser,
  getRegisterEvent,
  LoginType,
  MHubCustomer,
  MHubUser
} from './mhub/userManager'
import { MhubPage, useMhubPage } from './mhub/pageManager'
import useWaiter from 'hooks/useWaiter'
import { useMhubSession } from './mhub/sessionManager'
import { getEmptyMhubAds, getMhubAds } from './mhub/adsManager'
import { useRouter } from 'next/router'
import { useOrder } from './order/old/orderProvider'
import {
  getEmptyMhubCart,
  getMhubCart,
  getMhubCartAddEvent,
  getMhubCartAddMultipleEvent,
  getMhubCartRemoveEvent,
  MHubCart
} from './mhub/cartManager'
import { useWishlist } from './wishlistProvider'
import {
  getEmptyMhubWishlist,
  getMhubWishlist,
  getMhubWishlistAddEvent,
  getMhubWishlistRemoveEvent,
  MhubWishlist
} from './mhub/wishlistManager'
import {
  MhubImpressionProduct,
  useMhubImpression
} from './mhub/impressionManager'
import {
  getCategoryFilterAdd,
  getCategoryFilterRemove,
  getColorFilterAdd,
  getColorFilterRemove,
  getMaterialFilterAdd,
  getMaterialFilterRemove,
  getGenderFilterAdd,
  getGenderFilterRemove,
  getInStockFilterAdd,
  getInStockFilterRemove,
  getManufacturerFilterAdd,
  getManufacturerFilterRemove,
  getNewProductsFilterAdd,
  getNewProductsFilterRemove,
  getPriceFilterAdd,
  getPriceFilterRemove,
  getSizeChoose,
  getSizeFilterAdd,
  getSizeFilterRemove,
  getSortingFilterAdd,
  getTagFilterAdd,
  getTagFilterRemove,
  getInSaleFilterAdd,
  getInSaleFilterRemove,
  getMotiveFilterAdd,
  getMotiveFilterRemove,
  getMobileCategoriesQuickFilterAdd
} from './mhub/filterManager'
import { ActiveFiltersPriceType } from '@/types/activeFiltersType'
import { SlugPathItem } from '@/types/slugPath'
import { Routes } from '@/core/routes'
import { getEmptyMhubOrder, getMhubOrder, MHubOrder } from './mhub/orderManager'
import { ProductWithSize } from '@/types/productWithSize'
import { getImageMode } from '@/utils/imageExtension'
import { useDomain } from './storeProvider'
import { MHubBannerProps, useMhubTrackBanner } from './mhub/useMhubTrackBanner'
import {
  RatingEventObject,
  getPostProductRatingEvent
} from './mhub/ratingManager'
import { getProductSizeTablePositionEvent } from '@/providers/mhub/productManager'
import {
  ExperimentMhubItem,
  getExperimentEvent,
  getMhubEmptyExperimentEvent
} from '@/providers/mhub/experimentManager'
import { ID } from 'graphql-ws'

const GTM = {
  enabled: true,
  containers: {
    factcool: 'GTM-WLSCXDK',
    aliatic: 'GTM-K6BWVVQ',
    bezvasport: 'GTM-WMKLG4R',
    frogies: 'GTM-M5GRZ5T',
    filatic: 'GTM-WMKLG4R'
  }
}

const DataLayerName = 'mhub'

export const isMhubEnabled = (subdomain: string): boolean =>
  GTM.enabled && !!getGtmId(subdomain)
const getGtmId = (subdomain: string): string => GTM.containers[subdomain]

type MhubContextType = {
  pushAccountEditPage: (id: string) => void
  pushOrderDetailPage: (id: string) => void
  pushReturnDetailPage: (id: string) => void
  pushAddReturnPage: (id: string) => void
  pushReturnSuccessPage: () => void
  pushOrdersPage: () => void
  pushAddAddressPage: () => void
  pushNewsletterPage: () => void
  pushPasswordPage: () => void
  pushCampaignPage: () => void
  pushCampaignDetailPage: (campaign: Campaign) => void
  pushSimpleCampaignDetailPage: (campaign: Campaign, page: number) => void
  pushBlogPage: () => void
  pushBlogDetailPage: (blog: Blog) => void
  pushCategoryDetailPage: (category: SlugPathItem) => void
  pushSimpleCategoryDetailPage: (category: SlugPathItem, page: number) => void
  pushDiscountDetailPage: (category: Category) => void
  pushSimpleDiscountDetailPage: (category: Category, page: number) => void
  pushWarehouseDetailPage: (category: Category) => void
  pushSimpleWarehouseDetailPage: (category: Category, page: number) => void
  pushGiftsDetailPage: (giftBox: GiftBox) => void
  pushSimpleGiftsDetailPage: (giftBox: GiftBox, page: number) => void
  pushGiftCardCheckoutPage: () => void
  pushGiftCardCheckoutSuccessPage: () => void
  pushGiftCardPage: () => void
  pushInformationPage: (name: string) => void
  pushAboutPage: () => void
  pushB2bPage: () => void
  pushImpressumPage: () => void
  pushShopPage: () => void
  pushLandingPagePage: () => void
  pushMicropagePage: () => void
  pushProductDetailPage: (product: Product) => void
  pushProductDetailPopupPage: (product: Product) => void
  pushResetPasswordPage: () => void
  pushSpecialLandingPagePage: (manufacturer: Manufacturer) => void
  pushBrandsLandingPage: () => void
  pushWishlistPage: () => void
  pushAdventCalendarPage: () => void
  pushCartPage: (cart: Order) => Promise<void>
  pushCheckOrderPage: () => void
  pushCancelOrderPage: () => void
  pushGiftsPage: () => void
  pushCheckoutSuccessPage: (orderData: OrderData) => Promise<void>
  pushCheckoutWaitPage: (orderData: OrderData) => Promise<void>
  pushContactPage: () => void
  pushHiringPage: () => void
  pushHomePage: () => void
  pushLoginPage: () => void
  pushLostPasswordPage: () => void
  pushManufacturerPage: () => void
  pushManufacturerDetailPage: (manufacturer: Manufacturer) => void
  pushSimpleManufacturerDetailPage: (
    manufacturer: Manufacturer,
    page: number
  ) => void
  pushRegisterPage: () => void
  pushReturnPage: () => void
  pushSearchDetailPage: (term: string, resultCount: number) => void
  pushSimpleSearchDetailPage: (
    term: string,
    resultCount: number,
    page: number
  ) => void
  pushStep1Page: (cart: Order) => Promise<void>
  pushStep2Page: (cart: Order) => Promise<void>
  pushThankYouPage: () => void
  pushWishlistAddEvent: (
    product: Product,
    wishlist: (ID | Product)[]
  ) => Promise<void>
  pushWishlistRemoveEvent: (
    products: Product[],
    wishlist: Product[] | string[]
  ) => Promise<void>
  pushCartAddEvent: (
    product: Product,
    size: ProductSize,
    cart: Order
  ) => Promise<void>
  pushCartAddMultipleEvent: (
    products: ProductWithSize[],
    cart: Order
  ) => Promise<void>
  pushCartRemoveEvent: (
    data: { product: Product; size: ProductSize }[],
    cart: Order
  ) => Promise<void>
  pushImpression: (
    product: MhubImpressionProduct,
    position: number,
    list: string
  ) => void
  getImpressionAttributeData: (
    product: MhubImpressionProduct,
    list: string
  ) => string
  pushShowProductEvent: (product: MhubImpressionProduct, list: string) => void
  pushSearchWhisperer: (term: string, resultCount: number) => void
  pushSortingFilterAdd: (value: string) => void
  pushPriceFilterAdd: (value: ActiveFiltersPriceType) => void
  pushPriceFilterRemove: (value: ActiveFiltersPriceType) => void
  pushManufacturerFilterAdd: (value: string, name: string) => void
  pushManufacturerFilterRemove: (value: string, name: string) => void
  pushColorFilterAdd: (value: string, name: string) => void
  pushColorFilterRemove: (value: string, name: string) => void
  pushMotiveFilterAdd: (value: string, name: string) => void
  pushMotiveFilterRemove: (value: string, name: string) => void
  pushMaterialFilterAdd: (value: string, name: string) => void
  pushMaterialFilterRemove: (value: string, name: string) => void
  pushSizeFilterAdd: (value: string, name: string) => void
  pushSizeFilterRemove: (value: string, name: string) => void
  pushGenderFilterAdd: (value: string, name: string) => void
  pushGenderFilterRemove: (value: string, name: string) => void
  pushCategoryFilterAdd: (value: string, name: string) => void
  pushCategoryFilterRemove: (value: string, name: string) => void
  pushTagFilterAdd: (
    tagId: string,
    tagTitle: string,
    value: string,
    name: string
  ) => void
  pushTagFilterRemove: (
    tagId: string,
    tagTitle: string,
    value: string,
    name: string
  ) => void
  pushInStockFilterAdd: (name: string) => void
  pushInStockFilterRemove: (name: string) => void
  pushInSaleFilterAdd: (name: string) => void
  pushInSaleFilterRemove: (name: string) => void
  pushNewProductsFilterAdd: (name: string) => void
  pushNewProductsFilterRemove: (name: string) => void
  pushSizeChoose: (value: string) => void
  pushLoginEvent: (type: LoginType) => void
  pushRegisterEvent: (type: LoginType, customer?: MHubCustomer) => void
  pushErrorPage: (type: string, message: string) => void
  pushTop100Page: () => void
  pushSimpleTop100Page: (page: number) => void
  pushImpressBanners: (banner: MHubBannerProps) => void
  pushShowBanners: (banner: MHubBannerProps) => void
  pushCustomerFromMailing: (type: LoginType, customer: MHubCustomer) => void
  pushRSTSFromEmail: (customer: MHubCustomer) => void
  pushPostProductRatingEvent: (value: RatingEventObject) => void
  pushMobileCategoriesQuickFilterAdd: (value: string, name: string) => void
  pushProductSizeTablePositionEvent: (
    value: Product,
    supplierId: number,
    category: string
  ) => void
  pushExperimentEvent: (experiment: ExperimentMhubItem) => void
  pushMhubDefaultExperiments: () => void
}

const MhubContext = createContext<MhubContextType>(null)

export const useMhub = (): MhubContextType =>
  useContext(MhubContext) ?? useMhubDemo()

type MhubProviderProps = {
  ip: string
  serverTime: number
  referer: string
  seoTags: SeoTags
}

const MhubProvider: FC<
  MhubProviderProps & {
    domainName: string
  }
> = ({ domainName, children, ...rest }) => (
  <GTMProvider
    state={{
      id: getGtmId(domainName),
      dataLayerName: DataLayerName,
      dataLayer: {}
    }}
  >
    <MhubInternalProvider {...rest}>{children}</MhubInternalProvider>
  </GTMProvider>
)

const MhubInternalProvider: FC<MhubProviderProps> = ({
  ip,
  serverTime,
  referer,
  seoTags,
  children
}) => {
  const pushToMhub: (data: unknown) => void = useGTMDispatch()
  const { user, isLoggedIn } = useUser()
  const { order: cart } = useOrder()
  const { getMhubExperiments, getMhubDefaultExperiments } = useExperiment()
  const {
    getEmptyPage,
    getAccountEditPage,
    getOrderDetailPage,
    getReturnDetailPage,
    getAddReturnPage,
    getReturnSuccessPage,
    getOrdersPage,
    getAddAddressPage,
    getNewsletterPage,
    getPasswordPage,
    getCampaignPage,
    getCampaignDetailPage,
    getBlogPage,
    getBlogDetailPage,
    getCategoryDetailPage,
    getDiscountDetailPage,
    getWarehouseDetailPage,
    getGiftsDetailPage,
    getGiftCardCheckoutPage,
    getGiftCardCheckoutSuccessPage,
    getGiftCardPage,
    getInformationPage,
    getAboutPage,
    getB2bPage,
    getImpressumPage,
    getShopPage,
    getLandingPagePage,
    getMicropagePage,
    getProductDetailPage,
    getProductDetailPopupPage,
    getResetPasswordPage,
    getSpecialLandingPagePage,
    getBrandsLandingPage,
    getWishlistPage,
    getAdventCalendarPage,
    getCartPage,
    getCheckOrderPage,
    getCancelOrderPage,
    getGiftsPage,
    getCheckoutSuccessPage,
    getContactPage,
    getHiringPage,
    getHomePage,
    getLoginPage,
    getLostPasswordPage,
    getManufacturerPage,
    getManufacturerDetailPage,
    getRegisterPage,
    getReturnPage,
    getSearchDetailPage,
    getStep1Page,
    getStep2Page,
    getThankYouPage,
    getSearchWhispererPage,
    getErrorPage,
    getTop100Page
  } = useMhubPage(referer, seoTags)
  const { getSession } = useMhubSession(ip, serverTime)
  const { wait } = useWaiter()
  const router = useRouter()
  const { wishlist } = useWishlist()
  const { addImpression, getImpressionData, getShowProductEvent } =
    useMhubImpression(pushToMhub)
  const { subdomainName } = useDomain()
  const { addImpressBanner, getShowBanners } = useMhubTrackBanner(pushToMhub)

  useEffect(() => {
    if (isLoggedIn || router.pathname !== Routes.checkoutSuccess) {
      pushUser(user)
    }
  }, [user])

  useEffect(() => {
    if (router.pathname !== Routes.checkoutSuccess) {
      pushCart(cart)
    }
  }, [cart])

  useEffect(() => {
    pushWishlist(wishlist)
  }, [wishlist])

  const pushPageEvent = async (waitForWishlist = true): Promise<void> => {
    await wait(
      () => !!getUser() && !!getCart() && (!waitForWishlist || !!getWishlist())
    )
    pushToMhub({
      event: 'page'
    })
  }

  useEffect(() => {
    const fetchData = async (waitForWishlist = true) => {
      await wait(
        () =>
          !!getUser() &&
          !!getCart() &&
          !!getEventPage &&
          (!waitForWishlist || !!getWishlist())
      )
      setTimeout(() => {
        pushMhubDefaultExperiments()
      }, 500)
    }

    fetchData()
  }, [])

  const pushUser = (customer: MHubCustomer) => {
    if (customer?.email || !customer) {
      const lastUser = getUser()
      const newUser = getMhubUser(customer, getMhubExperiments())
      !isEqual(lastUser, newUser) && pushToMhub(newUser)
    }
  }

  const pushCart = (cart: Order) => {
    if (cart != null) {
      const lastCart = getCart()
      const newCart = getMhubCart(cart, getImageMode(subdomainName))
      if (!isEqual(lastCart, newCart)) {
        pushToMhub(getEmptyMhubCart(getImageMode(subdomainName)))
        newCart.cart !== null && pushToMhub(newCart)
      }
    }
    if (cart?.orderData != null) {
      const lastOrder = getOrder()
      const newOrder = getMhubOrder(cart?.orderData)
      if (!isEqual(lastOrder, newOrder)) {
        pushToMhub(newOrder)
      }
    }
  }

  const pushCartAddEvent = async (
    product: Product,
    size: ProductSize,
    cart: Order
  ) => {
    await wait(() =>
      isEqual(getMhubCart(cart, getImageMode(subdomainName)), getCart())
    )
    pushToMhub(
      getMhubCartAddEvent(product, size, getImageMode(subdomainName), cart)
    )
  }

  const pushCartAddMultipleEvent = async (
    products: ProductWithSize[],
    cart: Order
  ) => {
    await wait(() =>
      isEqual(getMhubCart(cart, getImageMode(subdomainName)), getCart())
    )
    pushToMhub(
      getMhubCartAddMultipleEvent(products, getImageMode(subdomainName), cart)
    )
  }

  const pushCartRemoveEvent = async (
    data: { product: Product; size: ProductSize }[],
    cart: Order
  ) => {
    await wait(() =>
      isEqual(getMhubCart(cart, getImageMode(subdomainName)), getCart())
    )
    pushToMhub(getMhubCartRemoveEvent(data, getImageMode(subdomainName), cart))
  }

  const pushWishlist = (wishlist: Product[]) => {
    if (wishlist !== null) {
      const lastWishlist = getWishlist()
      const newWishlist = getMhubWishlist(wishlist, getImageMode(subdomainName))
      if (!isEqual(lastWishlist, newWishlist)) {
        pushToMhub(getEmptyMhubWishlist(getImageMode(subdomainName)))
        newWishlist.wishlist !== null && pushToMhub(newWishlist)
      }
    }
  }

  const pushWishlistAddEvent = async (
    product: Product,
    wishlist: Product[]
  ) => {
    await wait(() =>
      isEqual(
        getMhubWishlist(wishlist, getImageMode(subdomainName)),
        getWishlist()
      )
    )
    pushToMhub(getMhubWishlistAddEvent(product, getImageMode(subdomainName)))
  }

  const pushWishlistRemoveEvent = async (
    products: Product[],
    wishlist: Product[]
  ) => {
    await wait(() =>
      isEqual(
        getMhubWishlist(wishlist, getImageMode(subdomainName)),
        getWishlist()
      )
    )
    pushToMhub(
      getMhubWishlistRemoveEvent(products, getImageMode(subdomainName))
    )
  }

  const onPageChange = async <T,>(
    data: MhubPage<T>[],
    waitForWishlist = true
  ): Promise<void> => {
    ;(data ?? []).forEach((item) => pushToMhub(item))
    pushToMhub(getSession())
    pushToMhub(getEmptyMhubAds())
    const ads = getMhubAds(router.asPath)
    ads.ads !== null && pushToMhub(ads)
    await pushPageEvent(waitForWishlist)
  }

  const pushAccountEditPage = (id: string) => {
    onPageChange([getEmptyPage(), getAccountEditPage(id)])
  }

  const pushOrderDetailPage = (id: string) => {
    onPageChange([getEmptyPage(), getOrderDetailPage(id)])
  }

  const pushReturnDetailPage = (id: string) => {
    onPageChange([getEmptyPage(), getReturnDetailPage(id)])
  }

  const pushAddReturnPage = (id: string) => {
    onPageChange([getEmptyPage(), getAddReturnPage(id)])
  }

  const pushReturnSuccessPage = () => {
    onPageChange([getEmptyPage(), getReturnSuccessPage()])
  }

  const pushOrdersPage = () => {
    onPageChange([getEmptyPage(), getOrdersPage()])
  }

  const pushAddAddressPage = () => {
    onPageChange([getEmptyPage(), getAddAddressPage()])
  }

  const pushNewsletterPage = () => {
    onPageChange([getEmptyPage(), getNewsletterPage()])
  }

  const pushPasswordPage = () => {
    onPageChange([getEmptyPage(), getPasswordPage()])
  }

  const pushCampaignPage = () => {
    onPageChange([getEmptyPage(), getCampaignPage()])
  }

  const pushCampaignDetailPage = (campaign: Campaign) => {
    onPageChange([getEmptyPage(), getCampaignDetailPage(campaign)])
  }

  const pushSimpleCampaignDetailPage = (campaign: Campaign, page: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getCampaignDetailPage(campaign, page))
    pushPageEvent()
  }

  const pushBlogPage = () => {
    onPageChange([getEmptyPage(), getBlogPage()])
  }

  const pushBlogDetailPage = (blog: Blog) => {
    onPageChange([getEmptyPage(), getBlogDetailPage(blog)])
  }

  const pushCategoryDetailPage = (category: SlugPathItem) => {
    onPageChange([getEmptyPage(), getCategoryDetailPage(category)])
  }

  const pushSimpleCategoryDetailPage = (
    category: SlugPathItem,
    page: number
  ) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getCategoryDetailPage(category, page))
    pushPageEvent()
  }

  const pushDiscountDetailPage = (category: Category) => {
    onPageChange([getEmptyPage(), getDiscountDetailPage(category)])
  }

  const pushSimpleDiscountDetailPage = (category: Category, page: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getDiscountDetailPage(category, page))
    pushPageEvent()
  }

  const pushWarehouseDetailPage = (category: Category) => {
    onPageChange([getEmptyPage(), getWarehouseDetailPage(category)])
  }

  const pushSimpleWarehouseDetailPage = (category: Category, page: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getWarehouseDetailPage(category, page))
    pushPageEvent()
  }

  const pushGiftsDetailPage = (giftBox: GiftBox) => {
    onPageChange([getEmptyPage(), getGiftsDetailPage(giftBox)])
  }

  const pushSimpleGiftsDetailPage = (giftBox: GiftBox, page: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getGiftsDetailPage(giftBox, page))
    pushPageEvent()
  }

  const pushGiftCardCheckoutPage = () => {
    onPageChange([getEmptyPage(), getGiftCardCheckoutPage()])
  }

  const pushGiftCardCheckoutSuccessPage = () => {
    onPageChange([getEmptyPage(), getGiftCardCheckoutSuccessPage()])
  }

  const pushGiftCardPage = () => {
    onPageChange([getEmptyPage(), getGiftCardPage()])
  }

  const pushInformationPage = (name: string) => {
    onPageChange([getEmptyPage(), getInformationPage(name)])
  }

  const pushAboutPage = () => {
    onPageChange([getEmptyPage(), getAboutPage()])
  }

  const pushB2bPage = () => {
    onPageChange([getEmptyPage(), getB2bPage()])
  }

  const pushImpressumPage = () => {
    onPageChange([getEmptyPage(), getImpressumPage()])
  }

  const pushShopPage = () => {
    onPageChange([getEmptyPage(), getShopPage()])
  }

  const pushLandingPagePage = () => {
    onPageChange([getEmptyPage(), getLandingPagePage()])
  }

  const pushMicropagePage = () => {
    onPageChange([getEmptyPage(), getMicropagePage()])
  }

  const pushProductDetailPage = (product: Product) => {
    onPageChange([getEmptyPage(), getProductDetailPage(product)])
  }

  const pushProductDetailPopupPage = (product: Product) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getProductDetailPopupPage(product))
    pushPageEvent()
  }

  const pushResetPasswordPage = () => {
    onPageChange([getEmptyPage(), getResetPasswordPage()])
  }

  const pushSpecialLandingPagePage = (manufacturer: Manufacturer) => {
    onPageChange([getEmptyPage(), getSpecialLandingPagePage(manufacturer)])
  }

  const pushBrandsLandingPage = () => {
    onPageChange([getEmptyPage(), getBrandsLandingPage()])
  }

  const pushWishlistPage = () => {
    onPageChange([getEmptyPage(), getWishlistPage()])
  }

  const pushAdventCalendarPage = () => {
    onPageChange([getEmptyPage(), getAdventCalendarPage()])
  }

  const pushCartPage = async (cart: Order) => {
    await wait(() => isEqual(getMhubOrder(cart?.orderData), getOrder()))
    onPageChange([getEmptyPage(), getCartPage()])
  }

  const pushCheckOrderPage = () => {
    onPageChange([getEmptyPage(), getCheckOrderPage()])
  }

  const pushCancelOrderPage = () => {
    onPageChange([getEmptyPage(), getCancelOrderPage()])
  }

  const pushGiftsPage = () => {
    onPageChange([getEmptyPage(), getGiftsPage()])
  }

  const pushCheckoutSuccessPage = async (orderData: OrderData) => {
    // page
    const page = getCheckoutSuccessPage(orderData?.id)
    if (page) {
      pushToMhub(getEmptyPage())
      pushToMhub(page)
    }
    // user
    if (!isLoggedIn) {
      pushToMhub(getMhubUser(orderData, getMhubExperiments()))
    }
    // cart
    pushToMhub(getEmptyMhubCart(getImageMode(subdomainName)))
    pushToMhub(
      getMhubCart(
        {
          products: orderData?.products
        } as Order,
        getImageMode(subdomainName)
      )
    )
    // order
    pushToMhub(getMhubOrder(orderData))
    // rest
    await onPageChange([])
    pushToMhub(getEmptyMhubOrder())
    pushToMhub(getEmptyMhubCart(getImageMode(subdomainName)))
  }

  const pushCheckoutWaitPage = async (orderData: OrderData) => {
    // page like pushCheckoutSuccessPage
    const page = getCheckoutSuccessPage(orderData?.id)
    if (page) {
      pushToMhub(getEmptyPage())
      pushToMhub(page)
    }
    // user
    if (!isLoggedIn) {
      pushToMhub(getMhubUser(orderData, getMhubExperiments()))
    }
    // cart
    pushToMhub(getEmptyMhubCart(getImageMode(subdomainName)))
    pushToMhub(
      getMhubCart(
        {
          products: orderData?.products
        } as Order,
        getImageMode(subdomainName)
      )
    )
    // order
    pushToMhub(getMhubOrder(orderData))
    // rest
    await onPageChange([])
    pushToMhub(getEmptyMhubOrder())
    pushToMhub(getEmptyMhubCart(getImageMode(subdomainName)))
  }

  const pushContactPage = () => {
    onPageChange([getEmptyPage(), getContactPage()])
  }

  const pushHiringPage = () => {
    onPageChange([getEmptyPage(), getHiringPage()])
  }

  const pushHomePage = () => {
    onPageChange([getEmptyPage(), getHomePage()])
  }

  const pushLoginPage = () => {
    onPageChange([getEmptyPage(), getLoginPage()])
  }

  const pushLostPasswordPage = () => {
    onPageChange([getEmptyPage(), getLostPasswordPage()])
  }

  const pushManufacturerPage = () => {
    onPageChange([getEmptyPage(), getManufacturerPage()])
  }

  const pushManufacturerDetailPage = (manufacturer: Manufacturer) => {
    onPageChange([getEmptyPage(), getManufacturerDetailPage(manufacturer)])
  }

  const pushSimpleManufacturerDetailPage = (
    manufacturer: Manufacturer,
    page: number
  ) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getManufacturerDetailPage(manufacturer, page))
    pushPageEvent()
  }

  const pushRegisterPage = () => {
    onPageChange([getEmptyPage(), getRegisterPage()])
  }

  const pushReturnPage = () => {
    onPageChange([getEmptyPage(), getReturnPage()])
  }

  const pushSearchDetailPage = (term: string, resultCount: number) => {
    onPageChange([getEmptyPage(), getSearchDetailPage(term, resultCount)])
  }

  const pushSimpleSearchDetailPage = (
    term: string,
    resultCount: number,
    page: number
  ) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getSearchDetailPage(term, resultCount, page))
    pushPageEvent()
  }

  const pushStep1Page = async (cart: Order) => {
    await wait(() => isEqual(getMhubOrder(cart?.orderData), getOrder()))
    onPageChange([getEmptyPage(), getStep1Page()])
  }

  const pushStep2Page = async (cart: Order) => {
    await wait(() => isEqual(getMhubOrder(cart?.orderData), getOrder()))
    onPageChange([getEmptyPage(), getStep2Page()])
  }

  const pushThankYouPage = () => {
    onPageChange([getEmptyPage(), getThankYouPage()])
  }

  const pushErrorPage = (type: string, message: string) => {
    onPageChange([getEmptyPage(), getErrorPage(type, message)], false)
  }

  const pushImpression = (
    product: MhubImpressionProduct,
    position: number,
    list: string
  ) => addImpression(product, position, list)

  const pushImpressBanners = (banner: MHubBannerProps) =>
    addImpressBanner(banner)

  const getImpressionAttributeData = (
    product: MhubImpressionProduct,
    list: string
  ) => JSON.stringify(getImpressionData(product, list))

  const pushShowProductEvent = (
    product: MhubImpressionProduct,
    list: string
  ): void => {
    pushToMhub(getShowProductEvent(product, list))
  }

  const pushShowBanners = (banner: MHubBannerProps): void => {
    pushToMhub(getShowBanners(banner))
  }

  const pushSearchWhisperer = (term: string, resultCount: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getSearchWhispererPage(term, resultCount))
    pushPageEvent()
  }

  const pushSortingFilterAdd = (value: string) =>
    pushToMhub(getSortingFilterAdd(value))

  const pushPriceFilterAdd = (value: ActiveFiltersPriceType) =>
    pushToMhub(getPriceFilterAdd(value))

  const pushPriceFilterRemove = (value: ActiveFiltersPriceType) =>
    pushToMhub(getPriceFilterRemove(value))

  const pushManufacturerFilterAdd = (value: string, name: string) =>
    pushToMhub(getManufacturerFilterAdd(value, name))

  const pushManufacturerFilterRemove = (value: string, name: string) =>
    pushToMhub(getManufacturerFilterRemove(value, name))

  const pushColorFilterAdd = (value: string, name: string) =>
    pushToMhub(getColorFilterAdd(value, name))

  const pushColorFilterRemove = (value: string, name: string) =>
    pushToMhub(getColorFilterRemove(value, name))

  const pushMotiveFilterAdd = (value: string, name: string) =>
    pushToMhub(getMotiveFilterAdd(value, name))

  const pushMotiveFilterRemove = (value: string, name: string) =>
    pushToMhub(getMotiveFilterRemove(value, name))

  const pushMaterialFilterAdd = (value: string, name: string) =>
    pushToMhub(getMaterialFilterAdd(value, name))

  const pushMaterialFilterRemove = (value: string, name: string) =>
    pushToMhub(getMaterialFilterRemove(value, name))

  const pushSizeFilterAdd = (value: string, name: string) =>
    pushToMhub(getSizeFilterAdd(value, name))

  const pushSizeFilterRemove = (value: string, name: string) =>
    pushToMhub(getSizeFilterRemove(value, name))

  const pushGenderFilterAdd = (value: string, name: string) =>
    pushToMhub(getGenderFilterAdd(value, name))

  const pushGenderFilterRemove = (value: string, name: string) =>
    pushToMhub(getGenderFilterRemove(value, name))

  const pushCategoryFilterAdd = (value: string, name: string) =>
    pushToMhub(getCategoryFilterAdd(value, name))

  const pushCategoryFilterRemove = (value: string, name: string) =>
    pushToMhub(getCategoryFilterRemove(value, name))

  const pushTagFilterAdd = (
    tagId: string,
    tagTitle: string,
    value: string,
    name: string
  ) => pushToMhub(getTagFilterAdd(tagId, tagTitle, value, name))

  const pushTagFilterRemove = (
    tagId: string,
    tagTitle: string,
    value: string,
    name: string
  ) => pushToMhub(getTagFilterRemove(tagId, tagTitle, value, name))

  const pushInStockFilterAdd = (name: string) =>
    pushToMhub(getInStockFilterAdd(name))

  const pushInStockFilterRemove = (name: string) =>
    pushToMhub(getInStockFilterRemove(name))

  const pushInSaleFilterAdd = (name: string) =>
    pushToMhub(getInSaleFilterAdd(name))

  const pushInSaleFilterRemove = (name: string) =>
    pushToMhub(getInSaleFilterRemove(name))

  const pushNewProductsFilterAdd = (name: string) =>
    pushToMhub(getNewProductsFilterAdd(name))

  const pushNewProductsFilterRemove = (name: string) =>
    pushToMhub(getNewProductsFilterRemove(name))

  const pushMobileCategoriesQuickFilterAdd = (value: string, name: string) => {
    pushToMhub(getMobileCategoriesQuickFilterAdd(value, name))
  }

  const pushSizeChoose = (value: string) => pushToMhub(getSizeChoose(value))

  const pushLoginEvent = async (type: LoginType) => {
    await wait(() => !!getUser())
    pushToMhub(getLoginEvent(type, getUser()))
  }

  const pushCustomerFromMailing = (type: LoginType, customer: MHubCustomer) => {
    pushToMhub(
      getCustomerFromMailing(type, getMhubUser(customer, getMhubExperiments()))
    )
  }

  const pushRSTSFromEmail = (customer: MHubCustomer) => {
    pushToMhub(getMhubUser(customer, getMhubExperiments()))
  }

  const pushMhubDefaultExperiments = () => {
    pushToMhub(getMhubDefaultExperiments())
    pushToMhub(getMhubEmptyExperimentEvent())
  }

  const pushRegisterEvent = async (
    type: LoginType,
    customer?: MHubCustomer
  ) => {
    if (customer) {
      pushToMhub(getMhubUser(customer, getMhubExperiments()))
    } else {
      await wait(() => !!getUser())
    }
    pushToMhub(getRegisterEvent(type, getUser()))
  }

  const pushTop100Page = () => {
    onPageChange([getEmptyPage(), getTop100Page()])
  }

  const pushSimpleTop100Page = (page: number) => {
    pushToMhub(getEmptyPage())
    pushToMhub(getTop100Page(page))
    pushPageEvent()
  }

  const pushPostProductRatingEvent = (value: RatingEventObject) => {
    pushToMhub(getPostProductRatingEvent(value))
  }

  const pushProductSizeTablePositionEvent = (
    value: Product,
    supplierId: number,
    category: string
  ) => {
    pushToMhub(getProductSizeTablePositionEvent(value, supplierId, category))
  }

  const pushExperimentEvent = async (
    experiment: ExperimentMhubItem,
    waitForWishlist = true
  ) => {
    await wait(
      () =>
        !!getUser() &&
        !!getCart() &&
        !!getEventPage &&
        (!waitForWishlist || !!getWishlist())
    )
    setTimeout(() => {
      pushToMhub(getExperimentEvent(experiment))
      pushToMhub(getMhubEmptyExperimentEvent())
    }, 500)
  }

  return (
    <MhubContext.Provider
      value={{
        pushAccountEditPage,
        pushOrderDetailPage,
        pushReturnDetailPage,
        pushAddReturnPage,
        pushReturnSuccessPage,
        pushOrdersPage,
        pushAddAddressPage,
        pushNewsletterPage,
        pushPasswordPage,
        pushCampaignPage,
        pushCampaignDetailPage,
        pushSimpleCampaignDetailPage,
        pushBlogPage,
        pushBlogDetailPage,
        pushCategoryDetailPage,
        pushSimpleCategoryDetailPage,
        pushDiscountDetailPage,
        pushSimpleDiscountDetailPage,
        pushWarehouseDetailPage,
        pushSimpleWarehouseDetailPage,
        pushGiftsDetailPage,
        pushSimpleGiftsDetailPage,
        pushGiftCardCheckoutPage,
        pushGiftCardCheckoutSuccessPage,
        pushGiftCardPage,
        pushInformationPage,
        pushAboutPage,
        pushB2bPage,
        pushImpressumPage,
        pushShopPage,
        pushLandingPagePage,
        pushMicropagePage,
        pushProductDetailPage,
        pushProductDetailPopupPage,
        pushResetPasswordPage,
        pushSpecialLandingPagePage,
        pushBrandsLandingPage,
        pushWishlistPage,
        pushAdventCalendarPage,
        pushCartPage,
        pushCheckOrderPage,
        pushCancelOrderPage,
        pushGiftsPage,
        pushCheckoutSuccessPage,
        pushCheckoutWaitPage,
        pushContactPage,
        pushHiringPage,
        pushHomePage,
        pushLoginPage,
        pushLostPasswordPage,
        pushManufacturerPage,
        pushManufacturerDetailPage,
        pushSimpleManufacturerDetailPage,
        pushRegisterPage,
        pushReturnPage,
        pushSearchDetailPage,
        pushStep1Page,
        pushStep2Page,
        pushThankYouPage,
        pushSimpleSearchDetailPage,
        pushWishlistAddEvent,
        pushWishlistRemoveEvent,
        pushCartAddEvent,
        pushCartAddMultipleEvent,
        pushCartRemoveEvent,
        pushImpression,
        getImpressionAttributeData,
        pushShowProductEvent,
        pushSearchWhisperer,
        pushSortingFilterAdd,
        pushPriceFilterAdd,
        pushPriceFilterRemove,
        pushManufacturerFilterAdd,
        pushManufacturerFilterRemove,
        pushColorFilterAdd,
        pushColorFilterRemove,
        pushMotiveFilterAdd,
        pushMotiveFilterRemove,
        pushMaterialFilterAdd,
        pushMaterialFilterRemove,
        pushSizeFilterAdd,
        pushSizeFilterRemove,
        pushGenderFilterAdd,
        pushGenderFilterRemove,
        pushCategoryFilterAdd,
        pushCategoryFilterRemove,
        pushTagFilterAdd,
        pushTagFilterRemove,
        pushInStockFilterAdd,
        pushInStockFilterRemove,
        pushInSaleFilterAdd,
        pushInSaleFilterRemove,
        pushNewProductsFilterAdd,
        pushNewProductsFilterRemove,
        pushSizeChoose,
        pushLoginEvent,
        pushRegisterEvent,
        pushErrorPage,
        pushTop100Page,
        pushSimpleTop100Page,
        pushImpressBanners,
        pushShowBanners,
        pushCustomerFromMailing,
        pushRSTSFromEmail,
        pushPostProductRatingEvent,
        pushMobileCategoriesQuickFilterAdd,
        pushProductSizeTablePositionEvent,
        pushExperimentEvent,
        pushMhubDefaultExperiments
      }}
    >
      {children}
    </MhubContext.Provider>
  )
}

export default MhubProvider
const getUser = (): MHubUser => getObjectFromMhub('user') as MHubUser
const getCart = (): MHubCart => getObjectFromMhub('cart') as MHubCart
const getOrder = (): MHubOrder => getObjectFromMhub('order') as MHubOrder
const getWishlist = (): MhubWishlist =>
  getObjectFromMhub('wishlist') as MhubWishlist

const getEventPage = (): unknown => getObjectFromMhub('event')

const getObjectFromMhub = (key: string, untilEventPage = false): unknown => {
  let items = [...(window[DataLayerName] ?? [])].reverse()
  if (untilEventPage) {
    const index = getIndexFromMhub('event', 'page')
    if (index !== -1) {
      items = items.slice(0, index)
    }
  }
  const item = items.find(
    (data) =>
      typeof data === 'object' &&
      !Array.isArray(data) &&
      Object.prototype.hasOwnProperty.call(data, key)
  )
  return item ? { [key]: item[key] } : null
}

const getIndexFromMhub = (key: string, value?: string): number =>
  [...(window[DataLayerName] ?? [])]
    .reverse()
    .findIndex(
      (data) =>
        typeof data === 'object' &&
        !Array.isArray(data) &&
        Object.prototype.hasOwnProperty.call(data, key) &&
        (!value || data[key] === value)
    )

const useMhubDemo = (): MhubContextType => ({
  pushAccountEditPage: () => null,
  pushOrderDetailPage: () => null,
  pushReturnDetailPage: () => null,
  pushAddReturnPage: () => null,
  pushReturnSuccessPage: () => null,
  pushOrdersPage: () => null,
  pushAddAddressPage: () => null,
  pushNewsletterPage: () => null,
  pushPasswordPage: () => null,
  pushCampaignPage: () => null,
  pushCampaignDetailPage: () => null,
  pushSimpleCampaignDetailPage: () => null,
  pushBlogPage: () => null,
  pushBlogDetailPage: () => null,
  pushCategoryDetailPage: () => null,
  pushSimpleCategoryDetailPage: () => null,
  pushDiscountDetailPage: () => null,
  pushSimpleDiscountDetailPage: () => null,
  pushWarehouseDetailPage: () => null,
  pushSimpleGiftsDetailPage: () => null,
  pushGiftsDetailPage: () => null,
  pushSimpleWarehouseDetailPage: () => null,
  pushGiftCardCheckoutPage: () => null,
  pushGiftCardCheckoutSuccessPage: () => null,
  pushGiftCardPage: () => null,
  pushInformationPage: () => null,
  pushAboutPage: () => null,
  pushB2bPage: () => null,
  pushImpressumPage: () => null,
  pushShopPage: () => null,
  pushLandingPagePage: () => null,
  pushMicropagePage: () => null,
  pushProductDetailPage: () => null,
  pushProductDetailPopupPage: () => null,
  pushResetPasswordPage: () => null,
  pushSpecialLandingPagePage: () => null,
  pushBrandsLandingPage: () => null,
  pushWishlistPage: () => null,
  pushAdventCalendarPage: () => null,
  pushCartPage: () => null,
  pushCheckOrderPage: () => null,
  pushCancelOrderPage: () => null,
  pushGiftsPage: () => null,
  pushCheckoutSuccessPage: () => null,
  pushCheckoutWaitPage: () => null,
  pushContactPage: () => null,
  pushHiringPage: () => null,
  pushHomePage: () => null,
  pushLoginPage: () => null,
  pushLostPasswordPage: () => null,
  pushManufacturerPage: () => null,
  pushManufacturerDetailPage: () => null,
  pushSimpleManufacturerDetailPage: () => null,
  pushRegisterPage: () => null,
  pushReturnPage: () => null,
  pushSearchDetailPage: () => null,
  pushStep1Page: () => null,
  pushStep2Page: () => null,
  pushThankYouPage: () => null,
  pushSimpleSearchDetailPage: () => null,
  pushWishlistAddEvent: () => null,
  pushWishlistRemoveEvent: () => null,
  pushCartAddEvent: () => null,
  pushCartAddMultipleEvent: () => null,
  pushCartRemoveEvent: () => null,
  pushImpression: () => null,
  getImpressionAttributeData: () => null,
  pushShowProductEvent: () => null,
  pushSearchWhisperer: () => null,
  pushSortingFilterAdd: () => null,
  pushPriceFilterAdd: () => null,
  pushPriceFilterRemove: () => null,
  pushManufacturerFilterAdd: () => null,
  pushManufacturerFilterRemove: () => null,
  pushColorFilterAdd: () => null,
  pushColorFilterRemove: () => null,
  pushMotiveFilterAdd: () => null,
  pushMotiveFilterRemove: () => null,
  pushMaterialFilterAdd: () => null,
  pushMaterialFilterRemove: () => null,
  pushSizeFilterAdd: () => null,
  pushSizeFilterRemove: () => null,
  pushGenderFilterAdd: () => null,
  pushGenderFilterRemove: () => null,
  pushCategoryFilterAdd: () => null,
  pushCategoryFilterRemove: () => null,
  pushTagFilterAdd: () => null,
  pushTagFilterRemove: () => null,
  pushInStockFilterAdd: () => null,
  pushInStockFilterRemove: () => null,
  pushInSaleFilterAdd: () => null,
  pushInSaleFilterRemove: () => null,
  pushNewProductsFilterAdd: () => null,
  pushNewProductsFilterRemove: () => null,
  pushSizeChoose: () => null,
  pushLoginEvent: () => null,
  pushRegisterEvent: () => null,
  pushErrorPage: () => null,
  pushTop100Page: () => null,
  pushSimpleTop100Page: () => null,
  pushImpressBanners: () => null,
  pushShowBanners: () => null,
  pushCustomerFromMailing: () => null,
  pushRSTSFromEmail: () => null,
  pushPostProductRatingEvent: () => null,
  pushMobileCategoriesQuickFilterAdd: () => null,
  pushProductSizeTablePositionEvent: () => null,
  pushExperimentEvent: () => null,
  pushMhubDefaultExperiments: () => null
})
