import { FC } from 'react'
import Link from '@/components/atoms/link'
import { notifyError, notifyInfo, notifySuccess } from '@/core/toast'
import { Routes } from '@/core/routes'
import {
  AddressInput,
  CartProduct,
  CartServiceType,
  DiscountError,
  Order,
  OrderCreationError,
  PaymentMethodType,
  Product,
  ProductError,
  ProductErrorType,
  ProductSize,
  Sdk,
  ShipperMethodType,
  ShippingLocationInput,
  ShippingMethodType,
  DeviceType,
  Discount
} from '@/network/graphql.g'
import { getSessionId } from '@/utils/sessionService'
import useGraphQl from 'hooks/useGraphQl'
import { Translate } from 'next-translate'
import Trans from 'next-translate/Trans'
import useTranslation from 'next-translate/useTranslation'
import { createContext, useContext, useRef } from 'react'
import { MessageType, useMessageDispatch } from '@/providers/messageProvider'
import styled from 'styled-components'
import { useOrder } from './orderProvider'
import { useMhub } from '@/providers/mhubProvider'
import useDevice from 'hooks/useDevice'
import { useCookie } from 'next-cookie'
import { Cookies } from '@/core/graphQlCookieManager'
import { ProductWithSize } from '@/types/productWithSize'
import { useDomain } from '@/providers/storeProvider'
import { DomainNames } from '@/types/stores'

type OrderDispatchContextType = {
  setOrder: (order: Order) => void
  addToCart: (
    product: Product,
    sizeId: ProductSize,
    gift: boolean,
    dispatchSuccess: boolean,
    withMHubOrder: boolean
  ) => Promise<boolean>
  addToCartMultiple: (
    products: ProductWithSize[],
    dispatchSuccess: boolean,
    withMHubOrder: boolean
  ) => Promise<boolean>
  updateCart: (
    product: Product,
    size: ProductSize,
    count: number
  ) => Promise<void>
  removeFromCart: (product: Product, size: ProductSize) => Promise<void>
  addCartService: (id: CartServiceType) => Promise<void>
  removeCartService: (id: CartServiceType) => Promise<void>
  reloadOrder: (order: Order) => Promise<void>
  updatePayment: (
    payment: PaymentMethodType,
    paymentOption?: string
  ) => Promise<boolean>
  updateShipping: (
    shipping: ShipperMethodType,
    shippingLocation: ShippingLocationInput
  ) => Promise<boolean>
  updateShippingLocation: (
    shippingLocation: ShippingLocationInput
  ) => Promise<boolean>
  addDiscount: (code: string, isAutomaticCoupon: boolean) => Promise<boolean>
  removeDiscount: (code: string, isAutomaticCoupon: boolean) => Promise<boolean>
  validateStep1: (order: Order) => string[]
  validateStep2: (order: Order) => string[]
  validateCart: (order: Order) => string[]
  finishOrder: (
    paymentAddress: AddressInput,
    shippingAddress: AddressInput,
    orderComment: string,
    paymentToken: string
  ) => Promise<Order>
  finishOrderNew: (paymentToken: string) => Promise<Order>
  updateAddress: (
    paymentAddress: AddressInput,
    shippingAddress: AddressInput,
    orderComment: string
  ) => Promise<Order>
  hasError: (order: Order, checkCreationErrors?: boolean) => boolean
  abortOrderReload: () => AbortSignal
  removeAllFromCart: (products: CartProduct[]) => Promise<void>
  showAutoCouponPopup: (discounts: Discount[]) => boolean
}

const OrderContextDispatch = createContext<OrderDispatchContextType>(null)

export const useOrderDispatch = (): OrderDispatchContextType =>
  useContext(OrderContextDispatch)

type OrderDispatchProviderProps = {
  ip: string
}

const OrderDispatchProvider: FC<OrderDispatchProviderProps> = ({
  ip,
  children
}) => {
  const { addMessage } = useMessageDispatch()
  const { domainName } = useDomain()
  const { t } = useTranslation('common')
  const reloadOrderAbortController = useRef<AbortController>(null)
  const { pushCartAddEvent, pushCartAddMultipleEvent, pushCartRemoveEvent } =
    useMhub()
  const { order: internalOrder, setOrder: setInternalOrder } = useOrder()

  const abortOrderReload = (): AbortSignal => {
    if (reloadOrderAbortController.current) {
      reloadOrderAbortController.current.abort()
    }
    reloadOrderAbortController.current = new AbortController()
    return reloadOrderAbortController.current.signal
  }

  const { requestNotifyError } = useGraphQl()

  const setOrder = (order: Order) => {
    if (order) {
      // remove not available products from cart
      const products: CartProduct[] = []
      order.products?.forEach((item) => {
        const errors = getProductAvailabilityErrors(order, item.product)
        const remove = errors.some((item) =>
          [
            ProductErrorType.BadProductId,
            ProductErrorType.BadSizeId,
            ProductErrorType.SizeNotForProduct
          ].includes(item.type)
        )
        const updateCount = errors.some(
          (item) => item.type === ProductErrorType.Availability
        )
        if (!remove) {
          products.push({
            ...item,
            ...{ count: updateCount ? item.availability : item.count }
          })
        }
      })
      setInternalOrder({ ...order, ...{ products } })
    }
  }
  const { isTablet, isDesktop } = useDevice()
  const removeFromCart = async (
    product: Product,
    size: ProductSize
  ): Promise<void> => {
    abortOrderReload()
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.removeFromCart,
      variables: {
        id: product.id,
        size: size?.id,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    if (result.success) {
      pushCartRemoveEvent([{ product, size }], result.response?.updateOrder)
    }

    const order = result.response?.updateOrder
    setOrder(order)

    dispatchModifiedProductErrors(order, product)
    dispatchMessages(order, t, [product])
  }

  const updateCart = async (
    product: Product,
    size: ProductSize,
    count: number
  ): Promise<void> => {
    abortOrderReload()
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updateCart,
      variables: {
        id: product.id,
        size: size?.id,
        count: count,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    const previousCount =
      internalOrder?.products?.find(
        (item) =>
          item?.product?.id === product.id && item?.size?.id === size?.id
      )?.count ?? 0

    if (previousCount < count) {
      pushCartAddEvent(product, size, result.response?.updateOrder)
    } else if (previousCount > count) {
      pushCartRemoveEvent([{ product, size }], result.response?.updateOrder)
    }

    const order = result.response?.updateOrder
    setOrder(order)

    dispatchModifiedProductErrors(order, product)
    dispatchMessages(order, t, [product])
  }

  const addCartService = async (id: CartServiceType): Promise<void> => {
    updateCartService(id, true)
  }

  const removeCartService = async (id: CartServiceType): Promise<void> => {
    updateCartService(id, false)
  }

  const updateCartService = async (
    id: CartServiceType,
    add: boolean
  ): Promise<void> => {
    abortOrderReload()
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updateCartService,
      variables: {
        id,
        added: add,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    const order = result.response?.updateOrder
    setOrder(order)
    dispatchMessages(order, t)
  }

  const addDiscount = async (
    code: string,
    isAutomaticCoupon: boolean
  ): Promise<boolean> => {
    return updateDiscount(code, true, isAutomaticCoupon)
  }

  const removeDiscount = async (
    code: string,
    isAutomaticCoupon: boolean
  ): Promise<boolean> => {
    return updateDiscount(code, false, isAutomaticCoupon)
  }

  const { request } = useGraphQl()

  const updateDiscount = async (
    code: string,
    add: boolean,
    isAutomaticCoupon: boolean
  ): Promise<boolean> => {
    abortOrderReload()
    const { success, response } = await request((sdk: Sdk) => ({
      method: sdk.updateDiscount,
      variables: {
        code: code.toUpperCase(),
        added: add,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    const order = response?.updateOrder
    setOrder(order)
    if (!isAutomaticCoupon) {
      dispatchMessages(order, t)
    }
    return success
  }

  const updatePayment = async (
    payment: PaymentMethodType,
    paymentOption: string
  ): Promise<boolean> => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updatePayment,
      variables: {
        payment,
        paymentOption,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    const order = response?.updateOrder
    setOrder(order)
    dispatchMessages(order, t)
    return success
  }

  const updateShipping = async (
    shipping: ShipperMethodType,
    shippingLocation: ShippingLocationInput
  ): Promise<boolean> => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updateShippingAndShippingLocation,
      variables: {
        shipping,
        shippingLocation,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    const order = response?.updateOrder
    setOrder(order)
    dispatchMessages(order, t)
    return success
  }

  const updateShippingLocation = async (
    shippingLocation: ShippingLocationInput
  ): Promise<boolean> => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updateShippingLocation,
      variables: {
        shippingLocation,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    const order = response?.updateOrder
    setOrder(order)
    dispatchMessages(order, t)
    return success
  }

  const finishOrder = async (
    paymentAddress: AddressInput,
    shippingAddress: AddressInput,
    orderComment: string,
    paymentToken: string
  ): Promise<Order> => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.finishOrder,
      variables: {
        paymentAddress,
        shippingAddress,
        orderComment,
        paymentToken,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    const order = response?.finishOrder
    setOrder(order)
    dispatchMessages(order, t, null, true)

    if (success) {
      const cookie = useCookie()
      cookie.remove('showAutomCouponPopup')
    }

    return success ? order : null
  }

  const finishOrderNew = async (paymentToken: string): Promise<Order> => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.finishOrderNew,
      variables: {
        sessionId: getSessionId(),
        ipAddress: ip,
        paymentToken,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    const order = response?.finishOrder
    setOrder(order)
    dispatchMessages(order, t, null, true)

    return success ? order : null
  }

  const updateAddress = async (
    paymentAddress: AddressInput,
    shippingAddress: AddressInput,
    orderComment: string
  ) => {
    abortOrderReload()
    const { success, response } = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.updateAddress,
      variables: {
        paymentAddress,
        shippingAddress,
        orderComment,
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    const order = response?.updateOrder
    setOrder(order)
    dispatchMessages(order, t)
    return success ? order : null
  }

  const addToCart = async (
    product: Product,
    size: ProductSize,
    gift: boolean,
    dispatchSuccess: boolean,
    withMHubOrder: boolean
  ): Promise<boolean> => {
    abortOrderReload()
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.addToCart,
      variables: {
        id: product.id,
        size: size?.id,
        cartServices: gift
          ? [
              {
                id: CartServiceType.GiftBox,
                added: true
              }
            ]
          : [],
        sessionId: getSessionId(),
        withMHubOrder,
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    if (result.success) {
      pushCartAddEvent(product, size, result.response?.addProductToCart)
    }

    const order = result.response?.addProductToCart
    setOrder(order)

    if (!dispatchModifiedProductErrors(order, product)) {
      if (dispatchSuccess) {
        addMessage(MessageType.AddedToCart)
        notifySuccess(
          <Trans
            i18nKey="common:ProductDetail.Modal.Toast.applySuccess"
            components={[<StyledLink href={Routes.cart} />]}
            values={{ product: product.name }}
          />,
          null,
          { autoClose: 2000 }
        )
        return showAutoCouponPopup(result.response.addProductToCart.discounts)
      }
    }
    dispatchMessages(order, t, [product])
    return false
  }

  //Show popup after success add to cart
  //Check isEnableDomain
  //Check checkCodeFromBe
  //Check cookie
  const showAutoCouponPopup = (discounts: Discount[]): boolean => {
    const codeToCheck = 'FROGIES40'
    const enableDomainList: DomainNames[] = [DomainNames.Frogies]
    const cookie = useCookie()
    const showCouponCookieName = 'showAutomCouponPopup'
    const cookieOptions = {
      maxAge: 24 * 60 * 60 * 7,
      path: '/'
    }

    const isEnableDomain = enableDomainList.includes(domainName as DomainNames)

    const checkCodeFromBe =
      discounts.length > 0 &&
      discounts.some((discount) => discount.code === codeToCheck)

    if (!isEnableDomain || !checkCodeFromBe) {
      return
    }

    const codeInCookie = cookie.get<string>(showCouponCookieName)

    if (!codeInCookie) {
      cookie.set(showCouponCookieName, true, cookieOptions)

      return true
    }

    return false
  }

  const addToCartMultiple = async (
    products: ProductWithSize[],
    dispatchSuccess: boolean,
    withMHubOrder: boolean
  ): Promise<boolean> => {
    abortOrderReload()
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.addToCartMultiple,
      variables: {
        products: products.map((item) => ({ id: item.id, size: item.size.id })),
        sessionId: getSessionId(),
        withMHubOrder,
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))

    if (result.success) {
      pushCartAddMultipleEvent(products, result.response?.addProductToCart)
    }

    const order = result.response?.addProductToCart
    setOrder(order)

    const isWithoutErrors = products.reduce(
      (acc, product) => acc && !dispatchModifiedProductErrors(order, product),
      true
    )

    if (isWithoutErrors) {
      if (dispatchSuccess) {
        addMessage(MessageType.AddedToCart)
        notifySuccess(
          <Trans
            i18nKey="common:ProductDetail.Modal.Toast.applyMultipleSuccess"
            components={[<StyledLink href={Routes.cart} />]}
          />,
          null,
          { autoClose: 2000 }
        )
      }
    }
    dispatchMessages(order, t, products)

    return result.success && isWithoutErrors
  }

  /**
   * @returns has errors
   */
  const dispatchModifiedProductErrors = (
    order: Order,
    product: Product
  ): boolean => {
    if (!order) {
      return true
    }

    const errors = getProductAvailabilityErrors(order, product)
    if (errors.length > 0) {
      errors.forEach((error) => {
        let text = ''
        switch (error.type) {
          case ProductErrorType.BadProductId:
          case ProductErrorType.SizeNotForProduct:
            text = t('ProductDetail.Errors.modifiedProduct.badProductId')
            break
          case ProductErrorType.BadSizeId:
            text = t('ProductDetail.Errors.modifiedProduct.badSizeId')
            break
          case ProductErrorType.Availability:
            text = t('ProductDetail.Errors.modifiedProduct.availability')
            break
        }
        if (text) {
          notifyError(text)
        }
      })
      return true
    } else {
      return false
    }
  }

  const reloadOrder = async (order: Order): Promise<void> => {
    setOrder(order)
    dispatchMessages(order, t)
  }

  const validateCart = (order: Order): string[] => {
    const errors: string[] = []
    if ((order?.products?.length ?? 0) === 0) {
      errors.push(t('Cart.Empty'))
    }
    return errors
  }

  const validateStep1 = (order: Order): string[] => {
    const errors: string[] = []
    const shipping = order?.shipping?.find(({ active }) => active) ?? null
    if (!shipping) {
      errors.push(t('Step1.Validation.emptyShipping'))
    }
    if (
      (shipping?.shippingMethodType === ShippingMethodType.Location ||
        shipping?.shippingMethodType === ShippingMethodType.Packeta ||
        shipping?.shippingMethodType === ShippingMethodType.Widget) &&
      !order?.shippingLocation
    ) {
      errors.push(t('Step1.Validation.emptyShippingLocation'))
    }

    const activePayments = order?.payments?.filter((payment) =>
      payment.methodOptions.find(({ active }) => active)
    )
    if ((activePayments?.length ?? 0) === 0) {
      errors.push(t('Step1.Validation.emptyPayment'))
    }

    return errors
  }

  const validateStep2 = (order: Order): string[] => {
    const errors: string[] = []
    if (
      !order?.email ||
      !order?.telephoneNumber ||
      !order?.paymentAddress ||
      !order?.paymentAddress?.firstname ||
      !order?.paymentAddress?.lastname ||
      (!order?.paymentAddress?.street && !order?.paymentAddress?.ac_street) ||
      !order?.paymentAddress?.streetNumber ||
      (!order?.paymentAddress?.city && !order?.paymentAddress?.ac_city) ||
      (!order?.paymentAddress?.ZIP && !order?.paymentAddress?.ac_zip)
    ) {
      errors.push(t('Step2.Validation.emptyPaymentAddress'))
    }
    return errors
  }

  const hasError = (order: Order, checkCreationErrors = true): boolean => {
    return (
      (order?.errors?.discountError?.length ?? 0) +
        (order?.errors?.formValidationError?.length ?? 0) +
        (checkCreationErrors
          ? order?.errors?.orderCreationError?.length ?? 0
          : 0) +
        (order?.errors?.productsAvailabilityError?.length ?? 0) >
      0
    )
  }

  const removeAllFromCart = async (products: CartProduct[]): Promise<void> => {
    const result = await requestNotifyError((sdk: Sdk) => ({
      method: sdk.removeAllFromCart,
      variables: {
        products: products.map((item) => ({
          id: item.product.id,
          size: item.size.id,
          count: 0
        })),
        sessionId: getSessionId(),
        ipAddress: ip,
        wasInAdmin: !!useCookie().get<string>(Cookies.factcoolAdminUser),
        device: isDesktop
          ? DeviceType.Desktop
          : isTablet
          ? DeviceType.Tablet
          : DeviceType.Mobile
      }
    }))
    if (result.success) {
      pushCartRemoveEvent(
        products.map(({ product, size }) => ({ product, size })),
        result.response?.updateOrder
      )
    }

    const order = result.response?.updateOrder
    setOrder(order)

    dispatchMessages(order, t)
  }

  return (
    <>
      <OrderContextDispatch.Provider
        value={{
          setOrder,
          addToCart,
          addToCartMultiple,
          updateCart,
          removeFromCart,
          addCartService,
          removeCartService,
          reloadOrder,
          updatePayment,
          updateShipping,
          updateShippingLocation,
          addDiscount,
          removeDiscount,
          validateStep1,
          validateStep2,
          validateCart,
          finishOrder,
          finishOrderNew,
          updateAddress,
          hasError,
          abortOrderReload,
          removeAllFromCart,
          showAutoCouponPopup
        }}
      >
        {children}
      </OrderContextDispatch.Provider>
    </>
  )
}

export default OrderDispatchProvider

const baseDispatchInfos = (order: Order): void => {
  order?.infos?.forEach((message) => {
    notifyInfo(message)
  })
}

const baseDispatchOtherProductsAvailabilityErrors = (
  order: Order,
  t: Translate,
  exceptProducts?: Product[]
): void => {
  const errors = getOtherProductsAvailabilityErrors(order, exceptProducts)
  if (errors.length > 0) {
    errors.forEach((error) => {
      let text = ''
      const cartProduct = order?.products?.find(
        (item) => item.product?.id === error.productId
      )
      if (cartProduct?.product) {
        switch (error.type) {
          case ProductErrorType.BadProductId:
          case ProductErrorType.SizeNotForProduct:
            text = t('ProductDetail.Errors.otherProducts.badProductId', {
              name: cartProduct?.product?.name
            })
            break
          case ProductErrorType.BadSizeId:
            text = t('ProductDetail.Errors.otherProducts.badSizeId', {
              name: cartProduct?.product?.name,
              size: cartProduct?.size?.value
            })
            break
          case ProductErrorType.Availability:
            if (cartProduct?.availability) {
              text = t('ProductDetail.Errors.otherProducts.availability', {
                name: cartProduct?.product?.name,
                count: cartProduct?.availability
              })
            } else {
              text = t('ProductDetail.Errors.otherProducts.availabilityZero', {
                name: cartProduct?.product?.name
              })
            }
            break
        }
      } else if (error.message) {
        text = error.message
      }

      if (text) {
        notifyError(text)
      }
    })
  }
}

const baseDispatchDiscountErrors = (order: Order): void => {
  const errors = getDiscountErrors(order)
  if (errors.length > 0) {
    errors.forEach((error) => {
      if (error?.message) {
        notifyError(error?.message)
      }
    })
  }
}

const baseDispatchOrderCreationErrors = (order: Order): void => {
  const errors = getOrderCreationErrors(order)
  if (errors.length > 0) {
    errors.forEach((error) => {
      if (error?.message) {
        notifyError(error?.message)
      }
    })
  }
}

export const dispatchMessages = (
  order: Order,
  t: Translate,
  exceptProducts?: Product[],
  dispatchCreationErrors = false
): void => {
  baseDispatchInfos(order)
  baseDispatchOtherProductsAvailabilityErrors(order, t, exceptProducts)
  baseDispatchDiscountErrors(order)
  dispatchCreationErrors && baseDispatchOrderCreationErrors(order)
}

const getProductAvailabilityErrors = (
  order: Order,
  product: Product
): ProductError[] =>
  order?.errors?.productsAvailabilityError?.filter(
    (item) => item.productId === product?.id
  ) ?? []

const getOtherProductsAvailabilityErrors = (
  order: Order,
  products?: Product[]
): ProductError[] =>
  order?.errors?.productsAvailabilityError?.filter(
    (item) =>
      !products || !products.map(({ id }) => id).includes(item.productId)
  ) ?? []

const getDiscountErrors = (order: Order): DiscountError[] =>
  order?.errors?.discountError ?? []

const getOrderCreationErrors = (order: Order): OrderCreationError[] =>
  order?.errors?.orderCreationError ?? []

export const isOrderPage = (path: string): boolean =>
  [Routes.cart, Routes.deliveryCost, Routes.deliveryAddress].includes(path)

const StyledLink = styled(Link)`
  display: inline-block;
  color: ${({ theme }) => theme.colors.accent};
`
