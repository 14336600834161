import React, { useContext } from 'react'
import {
  CountryId,
  Currency,
  MarketCode,
  Store,
  StoreId
} from '@/network/graphql.g'
import { ITheme } from '@/theme/interfaces/ITheme'
import { AppContext } from 'next/app'
import { GetServerSidePropsContext, NextApiRequest } from 'next'
import {
  defaultStore,
  Domain,
  DomainNames,
  StorePropertiesType,
  stores
} from '@/types/stores'
import { NextURL } from 'next/dist/server/web/next-url'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { IncomingMessage } from 'http'
import { getMarketFromTld } from '@/core/domainHelper'
import { isDevelopment } from '@/core/utils'

export const StoreContext = React.createContext<StoreContextType>(null)

export const useDomain = (): {
  domainName: string
  subdomainName: string
} => {
  const { domainName, subdomainName } = useContext(StoreContext) ?? {}
  return { domainName, subdomainName }
}
export const useCountryIsoCode = (): string =>
  useContext(StoreContext).countryIsoCode
export const useLocale = (): string => useContext(StoreContext).locale
export const useHost = (): string => useContext(StoreContext).host

const sentryEnabledMarkets: MarketCode[] = [
  MarketCode.Pl,
  MarketCode.It,
  MarketCode.Sk
]
export const isSentryEnabled = (host: string): boolean =>
  host &&
  sentryEnabledMarkets.includes(getHostData(host)?.subdomain as MarketCode)
export const sentryIgnoreErrors = [
  'Minified React error #423',
  'Minified React error #418',
  'Minified React error #421',
  'Hydration failed because the initial UI does not match what was rendered on the server.',
  'There was an error while hydrating',
  'Text content does not match server-rendered HTML.',
  '_AutofillCallbackHandler',
  '_pcmBridgeCallbackHandler',
  "Cannot read properties of null (reading 'insertBefore')",
  "null is not an object (evaluating 'parent.insertBefore')",
  "Cannot read property 'insertBefore' of null",
  'parent is null',
  'Cancel rendering route',
  "Failed to execute 'measure' on 'Performance'",
  'Non-Error promise rejection captured with value: Timeout' //recaptcha
]
export const sentryTracesSampleRate = isDevelopment ? 1.0 : 0.05
export const sentrySampleRate = isDevelopment ? 1.0 : 0.1

type StoreContextType = {
  storeId: StoreId
  theme: ITheme
  assetsFolder: string
  currency: Currency
  domainName: string
  subdomainName: string // language.code
  countryIsoCode: string // country.iso_code_2
  locale: string // language.locale (cs-CZ)
  isBezvasport: boolean
  isAliatic: boolean
  isFactcool: boolean
  isFilatic: boolean
  isFrogies: boolean
  host: string
  useWecomaCookie: boolean
}

type StoreProviderProps = {
  store: Store
  host: string
}

const StoreProvider: React.FC<StoreProviderProps> = ({
  store,
  host,
  children
}) => {
  // force different domain
  /*store = {
    id: StoreId.Factcool,
    name: 'Factcool',
    domainName: DomainNames.Factcool,
    market: {
      language: LanguageId.Slovak,
      name: 'Slovensko',
      code: MarketCode.Sk,
      country: CountryId.Sk,
      locale: 'sk-SK',
      currency: {
        leftSymbol: '',
        rightSymbol: ' €',
        decimalPlaces: 2
      }
    }
  }*/

  const storeConfig: StorePropertiesType =
    stores[store?.domainName] ?? defaultStore

  return (
    <StoreContext.Provider
      value={{
        storeId: storeConfig.storeId,
        theme: storeConfig.theme,
        assetsFolder: getAssetsFolder(store),
        currency: store?.market?.currency,
        domainName: storeConfig.domainName,
        countryIsoCode: getCountryIsoCode(store),
        locale: store?.market?.locale,
        subdomainName: store?.market?.code,
        isBezvasport: isBezvasport(store),
        isFrogies: isFrogies(store),
        isAliatic: isAliatic(store),
        isFactcool: isFactcool(store),
        isFilatic:
          store?.id === StoreId.Bezvasport &&
          store?.market?.code === MarketCode.Ro,
        host,
        useWecomaCookie: useWecomaCookie(store)
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

const getAssetsFolder = (store: Store): string => store?.domainName

const isBezvasport = (store: Store): boolean => store?.id === StoreId.Bezvasport

const isFrogies = (store: Store): boolean => store?.id === StoreId.Frogies

const isFactcool = (store: Store): boolean => store?.id === StoreId.Factcool

const isAliatic = (store: Store): boolean => store?.id === StoreId.Aliatic

export const getCountryIsoCode = (store: Store): CountryId =>
  store?.market?.country

export default StoreProvider

export const getHost = (
  ctx:
    | AppContext
    | GetServerSidePropsContext
    | {
        req:
          | NextApiRequest
          | (IncomingMessage & {
              cookies: NextApiRequestCookies
            })
      }
    | { protocol: string; host: string }
    | { headers: Headers; url: NextURL }
): string => {
  if ('protocol' in ctx && 'host' in ctx) {
    return `${ctx.protocol}://${ctx.host}`.replace(/\/$/, '')
  } else if ('url' in ctx && 'headers' in ctx) {
    return getHost({
      protocol: getProtocol(ctx.url),
      host: ctx.headers.get('host')
    })
  } else {
    let host =
      'ctx' in ctx ? ctx?.ctx?.req?.headers?.host : ctx?.req?.headers?.host
    if (!host && typeof window !== 'undefined') {
      host = window.location.host
    }
    return getHost({ protocol: getProtocol(ctx), host })
  }
}

const getProtocol = (
  ctx:
    | AppContext
    | GetServerSidePropsContext
    | {
        req:
          | NextApiRequest
          | (IncomingMessage & {
              cookies: NextApiRequestCookies
            })
      }
    | NextURL
): string => {
  if (process.env.IS_SECURED != null) {
    return process.env.IS_SECURED === '1' ? Protocol.Https : Protocol.Http
  } else if (ctx instanceof NextURL) {
    return ctx.protocol.replaceAll(':', '')
  } else {
    const socket = 'ctx' in ctx ? ctx?.ctx?.req?.socket : ctx?.req?.socket
    let protocol = Protocol.Https
    if (socket) {
      protocol = socket['encrypted'] ? Protocol.Https : Protocol.Http
    } else if (typeof window !== 'undefined') {
      protocol = window.location.protocol.replace(':', '') as Protocol
    }
    return protocol
  }
}

enum Protocol {
  Http = 'http',
  Https = 'https'
}

export const getHostData = (host: string): Domain & { tld: string } => {
  const hostSplit = (host?.toLowerCase() ?? '')
    .split(/[\s./]+/)
    .filter((el) => el.startsWith('http') === false)
  let domain: string
  let subdomain: string
  let tld: string
  if (hostSplit.length === 2) {
    domain = hostSplit[0]
    subdomain = getMarketFromTld(hostSplit[1].split(':')?.[0])
    tld = hostSplit[1].split(':')?.[0]
  } else if (hostSplit.length === 3) {
    domain = hostSplit[1]
    if (
      domain === DomainNames.Bezvasport ||
      domain === DomainNames.Frogies ||
      domain === DomainNames.Filatic
    ) {
      subdomain = getMarketFromTld(hostSplit[2].split(':')?.[0])
      tld = hostSplit[2].split(':')?.[0]
    } else {
      subdomain = hostSplit[0]
      tld = hostSplit[2].split(':')?.[0]
    }
  }
  const storeConfig: StorePropertiesType = stores[domain] ?? defaultStore

  return {
    domain: storeConfig.domainName,
    subdomain: subdomain ?? storeConfig.defaultSubdomainName,
    tld
  }
}

type WecomaConfig = {
  key: string
  locale: string
}

export const getWecomaConfig = (store: Store): WecomaConfig => {
  const config: WecomaConfig = { key: null, locale: null }
  if (isFactcool(store)) {
    config.key = 'fc'
  } else if (isFrogies(store)) {
    config.key = 'frog'
  } else if (isBezvasport(store)) {
    config.key = 'bs'
  } else if (isAliatic(store)) {
    config.key = 'al'
  }

  switch (store?.market?.code) {
    case MarketCode.En:
    case MarketCode.Fr:
    case MarketCode.Be:
    case MarketCode.Dk:
    case MarketCode.Fi:
    case MarketCode.Nl:
    case MarketCode.Se:
    case MarketCode.Pt:
      config.locale = 'en-GB'
      break
    case MarketCode.At:
      config.locale = 'de-DE'
      break
    default:
      config.locale = store?.market?.locale?.replace('_', '-')
  }

  return config
}

export const useWecomaCookie = (store: Store): boolean =>
  [
    MarketCode.Be,
    MarketCode.Dk,
    MarketCode.Fr,
    MarketCode.Nl,
    MarketCode.Pt,
    MarketCode.Fi,
    MarketCode.Se,
    MarketCode.At,
    MarketCode.De,
    MarketCode.Es,
    MarketCode.It,
    MarketCode.Hu,
    MarketCode.Ee,
    MarketCode.Lt,
    MarketCode.Cs,
    MarketCode.Lv,
    MarketCode.Sk
  ].includes(store?.market?.code)
